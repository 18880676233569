import React from 'react';
import {Avatar, Box, Heading, HStack, Progress, SimpleGrid, Text} from '@chakra-ui/react';
import {useQuery} from '@tanstack/react-query';
import {useNavigate} from 'react-router-dom';
import {Puddleglum} from '../../../puddleglum';

const MyProviders = () => {
	const navigate = useNavigate();

	const {data: providers, isLoading} = useQuery(['my-providers'], async () => {
		const reply = await Puddleglum.Controllers.Employee.MyProvidersController.index();

		return reply.data;
	});

	const openBookingRequest = (
		provider: Puddleglum.Models.Provider & {price: Puddleglum.Models.Price},
	) => {
		navigate('/booking-request', {
			state: {
				organization_id: provider.organizations![0].id,
				location_id: provider.locations![0].id,
				provider_id: provider.id,
				treatment_group_ids: [provider.price.treatment_group_id],
			},
		});
	};

	if (isLoading || !providers) {
		return <Progress size='xs' isIndeterminate />;
	}

	if (providers.length === 0) {
		return null;
	}

	return (
		<Box w='full'>
			<Heading mb={2} size='md' color='gray.500'>
				My Providers
			</Heading>
			<SimpleGrid columns={{base: 1, md: 2}} spacing={4}>
				{providers.map((provider) => (
					<HStack
						key={provider.id}
						role='button'
						w='full'
						h={{base: 'auto', md: '112px'}}
						bgColor='white'
						border='solid 1px'
						borderColor='gray.200'
						borderRadius='lg'
						p={4}
						spacing={4}
						_hover={{
							bgColor: 'gray.100',
						}}
						_active={{
							bgColor: 'gray.200',
						}}
						onClick={() => openBookingRequest(provider)}
					>
						<Avatar name={provider.name} src={provider.photo_url!} />
						<Box>
							<Text fontWeight='bold'>{provider.name}</Text>
							{provider.organizations?.map((organization) => (
								<Text key={organization.id} color='gray.500' noOfLines={1}>
									{organization.name}
								</Text>
							))}
							{provider.locations?.map((location) => (
								<Text key={location.id} color='gray.500' noOfLines={1}>
									{location.name}
								</Text>
							))}
							<Text color='gray.700' fontSize='sm' noOfLines={1}>
								Last seen: {provider.last_seen}
							</Text>
						</Box>
					</HStack>
				))}
			</SimpleGrid>
		</Box>
	);
};

export default MyProviders;
