import React, {FC, useEffect, useRef, useState} from 'react';
import {Box, BoxProps, Button, Heading, HStack, Icon, IconButton, Text} from '@chakra-ui/react';
import {MdArrowBack, MdArrowForward} from 'react-icons/md';
import PartitionOverColumn from '../../../components/PartitionOverColumn';

interface CommonSearchesProps extends BoxProps {
	onSelectSearch: (selection: string) => void;
}

const CommonProcedureSearches: FC<CommonSearchesProps> = ({onSelectSearch, ...boxProps}) => {
	const procedureContainer = useRef<HTMLDivElement>(null);
	const [canMoveFwd, setCanMoveFwd] = useState(true);
	const [canMoveBwd, setCanMoveBwd] = useState(false);

	const procedures = [
		'CBC Lab',
		'Comprehensive Metabolic Panel',
		'Lipid Panel',
		'Urinalysis',
		'TSH Test',
		'Hemoglobin A1c Test',
		'Basic Metabolic Panel',
		'Prothrombin Time Test',
		'CBC with Differential',
		'Vitamin D Test',
		'Ferritin Test',
		'Liver Function Tests',
		'Blood Glucose Test',
		'Pap Smear',
		'Mammogram',
		'Chest X-ray',
		'ECG/EKG',
		'Bone Density Scan',
		'Colonoscopy',
		'Endoscopy',
		'CT Scan',
		'MRI',
		'Ultrasound',
		'Spirometry',
		'Stool Test',
		'Urine Culture',
		'Throat Culture',
		'PSA Test',
		'HIV Test',
		'STD Tests',
	];

	const moveForward = () => {
		if (procedureContainer.current) {
			procedureContainer.current.scrollTo({
				left: procedureContainer.current.scrollLeft + 200,
				behavior: 'smooth',
			});
		}
	};

	const moveBackward = () => {
		if (procedureContainer.current) {
			procedureContainer.current.scrollTo({
				left: procedureContainer.current.scrollLeft - 200,
				behavior: 'smooth',
			});
		}
	};

	const updateScrollButtons = () => {
		if (procedureContainer.current) {
			setCanMoveFwd(
				procedureContainer.current.scrollLeft <
					procedureContainer.current.scrollWidth - procedureContainer.current.clientWidth,
			);
			setCanMoveBwd(procedureContainer.current.scrollLeft > 0);
		}
	};

	// eslint-disable-next-line consistent-return
	useEffect(() => {
		updateScrollButtons();
		if (procedureContainer.current) {
			procedureContainer.current.addEventListener('scroll', updateScrollButtons);

			const resizeObserver = new ResizeObserver(() => {
				updateScrollButtons();
			});
			resizeObserver.observe(procedureContainer.current);

			return () => {
				if (procedureContainer.current) {
					procedureContainer.current.removeEventListener('scroll', updateScrollButtons);
				}
				resizeObserver.disconnect();
			};
		}
	}, []);

	return (
		<Box {...boxProps} pos='relative'>
			<Heading mt={4} mb={4} size='md' color='gray.500'>
				Common Searches
			</Heading>
			<HStack ref={procedureContainer} w='full' overflowX='hidden'>
				<PartitionOverColumn childrenPerColumn={3}>
					{procedures.map((procedure) => (
						<Button
							key={procedure}
							onClick={() => onSelectSearch(procedure)}
							bgColor='white'
							size='sm'
							variant='outline'
							mt={2}
							pl={0}
							w='200px'
							justifyContent='flex-start'
							overflow='hidden'
							fontWeight='normal'
							color='gray.500'
							_hover={{
								color: 'blue.500',
								bgColor: 'blue.50',
								cursor: 'pointer',
							}}
						>
							<Text p={4}>{procedure}</Text>
						</Button>
					))}
				</PartitionOverColumn>
			</HStack>
			<IconButton
				onClick={moveBackward}
				display={canMoveBwd ? 'block' : 'none'}
				pos='absolute'
				left={4}
				top={20}
				bgColor='white'
				boxShadow='md'
				borderRadius='full'
				variant='outline'
				aria-label='Previous'
				icon={<Icon as={MdArrowBack} />}
			/>
			<IconButton
				onClick={moveForward}
				display={canMoveFwd ? 'block' : 'none'}
				pos='absolute'
				right={4}
				top={20}
				bgColor='white'
				boxShadow='md'
				borderRadius='full'
				variant='outline'
				aria-label='Next'
				icon={<Icon as={MdArrowForward} />}
			/>
		</Box>
	);
};

export default CommonProcedureSearches;
