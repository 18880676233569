import React, {FC, useState} from 'react';
import {
	Box,
	HStack,
	VStack,
	Text,
	Button,
	useDisclosure,
	BoxProps,
	Heading,
	IconButton,
} from '@chakra-ui/react';
import {MdAdd, MdDelete, MdEdit} from 'react-icons/md';
import useMe from '../../hooks/useMe';
import {confirm} from '../Confirm';
import DependentForm from './DependentForm';
import {Dependent} from './types';

interface DependentsProps extends BoxProps {
	initialValues?: Dependent[];
	onDependentFormOpen?: () => void;
	onDependentFormClose?: () => void;
	onChangeDependent?: (dependent: Dependent) => void;
	onChangeDependents?: (dependents: Dependent[]) => void;
	onRemoveDependent?: (dependent: Dependent) => void;
}

const Dependents: FC<DependentsProps> = ({
	initialValues = [],
	onDependentFormOpen,
	onDependentFormClose,
	onChangeDependent,
	onChangeDependents,
	onRemoveDependent,
	...boxProps
}) => {
	const [dependents, setDependents] = useState<Dependent[]>(initialValues);
	const [editedDependentIndex, setEditedDependentIndex] = useState<number>();
	const {me} = useMe();
	const {isOpen, onOpen, onClose} = useDisclosure();

	const handleOpenDependent = (index?: number) => {
		if (index !== undefined) {
			setEditedDependentIndex(index);
		}

		onOpen();
		onDependentFormOpen?.();
	};

	const handleCloseDependent = () => {
		setEditedDependentIndex(undefined);
		onClose();
		onDependentFormClose?.();
	};

	const handleSaveDependent = (dependent: Dependent) => {
		const updatedDependents =
			editedDependentIndex !== undefined
				? dependents.map((d, i) => (i === editedDependentIndex ? dependent : d))
				: [...dependents, dependent];

		setDependents(updatedDependents);
		setEditedDependentIndex(undefined);
		onClose();
		onChangeDependents?.(updatedDependents);
		onChangeDependent?.(dependent);
		onDependentFormClose?.();
	};

	const handleRemoveDependent = (index: number) => {
		const removedDependent = dependents[index];

		confirm({
			title: `Are you want to remove ${removedDependent.first_name} ${removedDependent.last_name} as a dependent?`,
			onOk: () => {
				const updatedDependents = dependents.filter((_, i) => i !== index);
				setDependents(updatedDependents);
				onChangeDependents?.(updatedDependents);
				onRemoveDependent?.(dependents[index]);
			},
		});
	};

	if (!me) return null;

	if (isOpen) {
		return (
			<Box {...boxProps}>
				<Heading size='sm'>Enter Dependent Information</Heading>
				<DependentForm
					dependent={
						editedDependentIndex !== undefined
							? dependents[editedDependentIndex]
							: undefined
					}
					onSubmit={handleSaveDependent}
					onClose={handleCloseDependent}
				/>
			</Box>
		);
	}

	return (
		<Box {...boxProps}>
			<VStack spacing={4} alignItems='stretch'>
				{dependents.length === 0 && (
					<Text color='gray.500'>You currently have no listed dependents.</Text>
				)}

				{dependents.map((dependent, index) => (
					<Box
						key={dependent.first_name + dependent.last_name + dependent.date_of_birth}
						p={4}
						bgColor='white'
						borderWidth='1px'
						borderRadius='lg'
					>
						<HStack w='full' spacing={4} fontSize='sm' justifyContent='space-between'>
							<Box flex={3}>
								{dependent.first_name} {dependent.last_name}
							</Box>
							<Box flex={2}>{dependent.date_of_birth}</Box>
							{!me.inactivated_at && (
								<Box>
									<IconButton
										aria-label='Edit'
										variant='ghost'
										size='sm'
										icon={<MdEdit />}
										onClick={() => handleOpenDependent(index)}
									/>
									<IconButton
										aria-label='remove'
										variant='ghost'
										size='sm'
										icon={<MdDelete />}
										onClick={() => handleRemoveDependent(index)}
									/>
								</Box>
							)}
						</HStack>
					</Box>
				))}
			</VStack>

			{!me.inactivated_at && (
				<Button size='sm' leftIcon={<MdAdd />} mt={4} onClick={() => handleOpenDependent()}>
					Add Dependent
				</Button>
			)}
		</Box>
	);
};

export default Dependents;
