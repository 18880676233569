import React from 'react';
import {
	Button,
	Center,
	Icon,
	Modal,
	ModalBody,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import {MdWarning} from 'react-icons/md';

interface MedicalEmergencyModalProps {
	isOpen: boolean;
	onConfirm: () => void;
	onClose: () => void;
}

const MedicalEmergencyModal = ({isOpen, onConfirm, onClose}: MedicalEmergencyModalProps) => (
	<Modal size='xs' isOpen={isOpen} onClose={onClose} isCentered>
		<ModalOverlay />
		<ModalContent>
			<Center pt={8}>
				<Icon as={MdWarning} boxSize={8} color='red.500' />
			</Center>

			<ModalHeader textAlign='center' color='gray.900'>
				Is this a medical emergency?
			</ModalHeader>
			<ModalBody>
				<Text color='gray.500' textAlign='center'>
					This application is not designed for medical emergencies. If you believe you are
					having a medical emergency, please call 911 or visit the emergency room.
				</Text>

				<Button mt={6} w='full' colorScheme='teal' onClick={onConfirm}>
					This is not a medical emergency
				</Button>
				<Button my={2} w='full' variant='ghost' onClick={onClose}>
					Close
				</Button>
			</ModalBody>
		</ModalContent>
	</Modal>
);

export default MedicalEmergencyModal;
