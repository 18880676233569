import React, {FC, useState} from 'react';
import {Button, FormControl, FormLabel, HStack, Input, VStack} from '@chakra-ui/react';
import {useIMask} from 'react-imask';
import {Dependent} from './types';

interface DependentFormProps {
	dependent?: Dependent;
	onClose?: () => void;
	onSubmit: (dependent: Dependent) => void;
}

const DependentForm: FC<DependentFormProps> = ({dependent, onClose, onSubmit}) => {
	const [firstName, setFirstName] = useState(dependent?.first_name ?? '');
	const [lastName, setLastName] = useState(dependent?.last_name ?? '');
	const [dateOfBirth, setDateOfBirth] = useState(dependent?.date_of_birth ?? '');
	const [email, setEmail] = useState(dependent?.email ?? '');
	const [cellPhone, setCellPhone] = useState(dependent?.cell_phone ?? '');
	const {ref: cellPhoneRef} = useIMask(
		{
			mask: '(000) 000-0000',
		},
		{
			onAccept: (value: unknown) => {
				setCellPhone(value as string);
			},
		},
	);

	const handleClose = () => {
		onClose?.();
	};

	const isFormReady = firstName && lastName && dateOfBirth;

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				onSubmit({
					id: dependent?.id,
					first_name: firstName,
					last_name: lastName,
					date_of_birth: dateOfBirth,
					email,
					cell_phone: cellPhone,
				});
			}}
		>
			<VStack
				w='full'
				justifyContent='stretch'
				mt={4}
				p={4}
				spacing={4}
				borderRadius='md'
				border='solid 1px'
				borderColor='gray.200'
				bgColor='white'
			>
				<FormControl isRequired>
					<FormLabel fontSize='sm' color='gray.500'>
						First Name
					</FormLabel>
					<Input
						type='text'
						bgColor={!firstName ? 'yellow.100' : 'white'}
						value={firstName}
						onChange={(e) => setFirstName(e.target.value)}
					/>
				</FormControl>

				<FormControl isRequired>
					<FormLabel fontSize='sm' color='gray.500'>
						Last Name
					</FormLabel>
					<Input
						type='text'
						bgColor={!lastName ? 'yellow.100' : 'white'}
						value={lastName}
						onChange={(e) => setLastName(e.target.value)}
					/>
				</FormControl>

				<FormControl isRequired>
					<FormLabel fontSize='sm' color='gray.500'>
						Date of Birth
					</FormLabel>
					<Input
						type='date'
						bgColor={!dateOfBirth ? 'yellow.100' : 'white'}
						value={dateOfBirth}
						onChange={(e) => setDateOfBirth(e.target.value)}
					/>
				</FormControl>

				<FormControl>
					<FormLabel fontSize='sm' color='gray.500'>
						Email (Optional)
					</FormLabel>
					<Input
						type='email'
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						isDisabled={!!dependent?.id && !!dependent.email}
					/>
				</FormControl>

				<FormControl>
					<FormLabel fontSize='sm' color='gray.500'>
						Cell Phone (Optional)
					</FormLabel>
					<Input type='tel' ref={cellPhoneRef as React.RefObject<HTMLInputElement>} />
				</FormControl>

				<HStack w='full' justifyContent='flex-end'>
					<Button variant='ghost' onClick={handleClose}>
						Cancel
					</Button>
					<Button colorScheme='blue' type='submit' isDisabled={!isFormReady}>
						{dependent ? 'Update' : 'Add'}
					</Button>
				</HStack>
			</VStack>
		</form>
	);
};

export default DependentForm;
