import React from 'react';
import {
	StackProps,
	Button,
	HStack,
	Image,
	Spacer,
	useBreakpointValue,
	IconButton,
	VStack,
	Box,
	SlideFade,
} from '@chakra-ui/react';
import {MdClose, MdMenu} from 'react-icons/md';
import {useLocation, useNavigate} from 'react-router-dom';
import NotificationBell from '../../../components/NotificationBell';
import UserOrb from '../../../components/UserOrb';
import assetUrl from '../../../utils/assetUrl';

interface NavigationMenuProps extends StackProps {}

const NavigationMenu = ({...stackProps}: NavigationMenuProps) => {
	const [isMenuOpen, setIsMenuOpen] = React.useState(false);
	const location = useLocation();

	const isMobile = useBreakpointValue({
		base: true,
		sm: true,
		md: false,
	});
	const navigate = useNavigate();

	const isSelectedProps = (path: string) =>
		location.pathname === path
			? {
					color: 'gray.900',
					borderBottom: '2px solid',
					borderColor: 'teal.500',
				}
			: {};

	const MenuButtons = () => (
		<>
			<Button variant='navigation' onClick={() => navigate('/')} {...isSelectedProps('/')}>
				Home
			</Button>
			<Button
				variant='navigation'
				onClick={() => navigate('/personal-information')}
				{...isSelectedProps('/personal-information')}
			>
				My Account
			</Button>
			<Button
				variant='navigation'
				onClick={() => navigate('/past-visits')}
				{...isSelectedProps('/past-visits')}
			>
				Past Visits
			</Button>
			<Button
				variant='navigation'
				onClick={() => navigate('/payment-history')}
				{...isSelectedProps('/payment-history')}
			>
				Payment History
			</Button>
			<Button
				variant='navigation'
				onClick={() => navigate('/support')}
				{...isSelectedProps('/support')}
			>
				Support
			</Button>
			<Button
				variant='navigation'
				onClick={() => navigate('/digital-card')}
				{...isSelectedProps('/digital-card')}
			>
				Digital Card
			</Button>
		</>
	);

	return (
		<>
			<HStack spacing={8} maxW='1000px' m='auto' {...stackProps}>
				{isMobile ? (
					<>
						<IconButton
							aria-label='Menu'
							icon={<MdMenu />}
							variant='ghost'
							onClick={() => setIsMenuOpen(!isMenuOpen)}
						/>
						<Image
							src={assetUrl('/images/millennia-logo.png')}
							alt='Millennia Health'
							w='42px'
						/>
					</>
				) : (
					<>
						<Image
							src={assetUrl('/images/millennia-logo.png')}
							alt='Millennia Health'
							w='42px'
						/>
						<MenuButtons />
					</>
				)}
				<Spacer />
				<NotificationBell right={4} top={-2} />
				<UserOrb
					pos='relative'
					left={undefined}
					bottom={undefined}
					top={undefined}
					zIndex={undefined}
					avatarMargin={0}
				/>
			</HStack>
			{isMenuOpen && (
				<Box
					bgColor='white'
					p={4}
					pos='fixed'
					top={0}
					left={0}
					right={0}
					bottom={0}
					zIndex='sticky'
				>
					<SlideFade in={isMenuOpen} unmountOnExit>
						<VStack w='full' alignItems='flex-start' spacing={4}>
							<HStack w='full' justifyContent='space-between'>
								<Image
									src={assetUrl('/images/millennia-logo.png')}
									alt='Millennia Health'
									w='42px'
								/>
								<IconButton
									aria-label='Close'
									icon={<MdClose />}
									variant='ghost'
									onClick={() => setIsMenuOpen(!isMenuOpen)}
								/>
							</HStack>
							<MenuButtons />
						</VStack>
					</SlideFade>
				</Box>
			)}
		</>
	);
};

export default NavigationMenu;
