import React, {FC, useEffect, useState} from 'react';
import {
	Box,
	Button,
	FormControl,
	Input,
	Stack,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	useBreakpointValue,
	useDisclosure,
} from '@chakra-ui/react';
import {MdSearch} from 'react-icons/md';
import {createSearchParams, useNavigate} from 'react-router-dom';
import LoadingModal from '../../../components/LoadingModal';
import useMe from '../../../hooks/useMe';
import CommonProcedureSearches from './CommonProcedureSearches';
import CommonSpecialtySearches from './CommonSpecialtySearches';
import MedicalEmergencyModal from './MedicalEmergencyModal';

interface MedicalEmergencyPopupProps {}

const MedicalCareSearch: FC<MedicalEmergencyPopupProps> = () => {
	const {me} = useMe();
	const {
		isOpen: isMedicalEmergencyModalOpen,
		onOpen: onMedicalEmergencyModalOpen,
		onClose: onMedicalEmergencyModalClose,
	} = useDisclosure();
	const {
		isOpen: isLoadingModalOpen,
		onOpen: onLoadingModalOpen,
		onClose: onLoadingModalClose,
	} = useDisclosure();
	const [typeOfSearch, setTypeOfSearch] = useState<'treatments' | 'providers'>('providers');
	const [placeholder, setPlaceholder] = useState('');
	const [searchText, setSearchText] = useState('');
	const navigate = useNavigate();
	const isMobile = useBreakpointValue({
		base: true,
		sm: false,
	});
	useEffect(() => {
		const providerPlaceholders = [
			'e.g., Family physician',
			'e.g., Cardiologist',
			'e.g., Pediatrician',
			'e.g., Urgent care',
		];

		const procedurePlaceholders = [
			'e.g., Lipid panel',
			'e.g., Mammogram',
			'e.g., Colonoscopy',
			'e.g., CT scan of my neck',
		];

		function updatePlaceholder() {
			if (typeOfSearch === 'providers') {
				setPlaceholder(
					providerPlaceholders[Math.floor(Math.random() * providerPlaceholders.length)],
				);
			} else {
				setPlaceholder(
					procedurePlaceholders[Math.floor(Math.random() * procedurePlaceholders.length)],
				);
			}
		}

		updatePlaceholder();

		const interval = setInterval(updatePlaceholder, 5000);

		return () => clearInterval(interval);
	}, [typeOfSearch]);

	const performSearch = async () => {
		navigate({
			pathname:
				typeOfSearch === 'treatments' ? '/procedure-selection' : '/provider-search-results',
			search: createSearchParams({
				search: searchText,
			}).toString(),
		});
	};

	const handleSearchButtonClick = () => {
		onMedicalEmergencyModalOpen();
	};

	const handleNotEmergencyClick = async () => {
		onMedicalEmergencyModalClose();
		onLoadingModalOpen();
		performSearch();
	};

	const handleCommonSearchClick = (selection: string) => {
		setSearchText(selection);
		onMedicalEmergencyModalOpen();
	};

	if (!me || me.inactivated_at) return null;

	return (
		<Box w='full'>
			<Tabs
				size={{base: 'lg', md: 'md'}}
				variant='soft-rounded'
				colorScheme='blue'
				onChange={(index) => setTypeOfSearch(index === 0 ? 'providers' : 'treatments')}
			>
				<TabList>
					<Tab>{isMobile ? 'Providers' : 'Search for a Provider'}</Tab>
					<Tab>{isMobile ? 'Procedures' : 'Search for a Procedure'}</Tab>
				</TabList>
				<TabPanels>
					<TabPanel px={0} pb={0}>
						<Stack direction={{base: 'column', md: 'row'}} spacing={4}>
							<FormControl>
								<Input
									size={{base: 'lg', md: 'md'}}
									bgColor='white'
									placeholder={placeholder}
									onChange={(e) => setSearchText(e.target.value)}
								/>
							</FormControl>
							<Button
								isDisabled={!searchText}
								leftIcon={<MdSearch />}
								minW='400px'
								size={{base: 'lg', md: 'md'}}
								colorScheme='blue'
								onClick={handleSearchButtonClick}
							>
								Search
							</Button>
						</Stack>
						<CommonSpecialtySearches mt={8} onSelectSearch={handleCommonSearchClick} />
					</TabPanel>
					<TabPanel px={0} pb={0}>
						<Stack direction={{base: 'column', md: 'row'}} spacing={4}>
							<FormControl>
								<Input
									size={{base: 'lg', md: 'md'}}
									bgColor='white'
									placeholder={placeholder}
									onChange={(e) => setSearchText(e.target.value)}
								/>
							</FormControl>
							<Button
								leftIcon={<MdSearch />}
								colorScheme='blue'
								size={{base: 'lg', md: 'md'}}
								onClick={handleSearchButtonClick}
							>
								Search
							</Button>
						</Stack>
						<CommonProcedureSearches mt={8} onSelectSearch={handleCommonSearchClick} />
					</TabPanel>
				</TabPanels>
			</Tabs>
			<MedicalEmergencyModal
				isOpen={isMedicalEmergencyModalOpen}
				onConfirm={handleNotEmergencyClick}
				onClose={onMedicalEmergencyModalClose}
			/>
			<LoadingModal
				estimatedTime={4000}
				steps={[
					'Querying the AI engine...',
					'Identifying possible results...',
					'Mapping results to organizations...',
					'Loading prices...',
					'Preparing your results...',
				]}
				onClose={onLoadingModalClose}
				isOpen={isLoadingModalOpen}
			/>
		</Box>
	);
};

export default MedicalCareSearch;
