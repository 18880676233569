import React from 'react';
import {InfoIcon} from '@chakra-ui/icons';
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	InputProps,
	Tooltip,
} from '@chakra-ui/react';
import {useIMask} from 'react-imask';
import {PuddleBag} from '../../hooks/usePuddleForm';

interface PuddleMaskedInputProps<TModel, TRequest> {
	mask: string;
	label: string;
	type?: 'text' | 'email' | 'password' | 'number' | 'tel' | 'url';
	fieldName: keyof TRequest;
	form: PuddleBag<TModel, TRequest>;
	isRequired?: boolean;
	inputProps?: InputProps;
	disabled?: boolean;
	informationalText?: string;
}

const PuddleMaskedInput = <TModel, TRequest>({
	mask,
	label,
	fieldName,
	type = 'text',
	form,
	isRequired = false,
	inputProps = {},
	disabled = false,
	informationalText,
}: PuddleMaskedInputProps<TModel, TRequest>) => {
	const value =
		form.model && form.model[fieldName as unknown as keyof TModel]
			? (form.model[fieldName as unknown as keyof TModel] as string)
			: '';

	const {ref} = useIMask(
		{
			mask,
		},
		{
			onAccept: (newValue: unknown) => {
				form.updateField(fieldName, newValue);
			},
		},
	);

	return (
		<FormControl isInvalid={form.fieldHasError(fieldName)} isRequired={isRequired}>
			<FormLabel color='gray.500'>
				{label}
				{informationalText && (
					<Tooltip label={informationalText}>
						<InfoIcon ml={2} />
					</Tooltip>
				)}
			</FormLabel>
			<Input
				ref={ref as React.RefObject<HTMLInputElement>}
				defaultValue={value}
				bgColor='white'
				type={type}
				onBlur={(e) => form.validateField(fieldName, e.target.value)}
				{...inputProps}
				disabled={disabled}
			/>
			{form.fieldHasError(fieldName) && (
				<FormErrorMessage fontSize='xs'>{form.getFieldError(fieldName)}</FormErrorMessage>
			)}
		</FormControl>
	);
};

export default PuddleMaskedInput;
