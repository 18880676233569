import React from 'react';
import {Box, BoxProps, Center} from '@chakra-ui/react';

interface MobilePageContainerProps extends BoxProps {}

const MobilePageContainer: React.FC<MobilePageContainerProps> = ({children, ...props}) => (
	<Center w='100%' p={{base: 'none', sm: 8}}>
		<Box
			mt={{base: 20, md: 0}}
			pos={{base: 'fixed', md: 'relative'}}
			top={0}
			bottom={0}
			overflowY='auto'
			w='full'
			maxW='1000px'
			rounded={{base: 'none', md: 'xl'}}
			boxShadow='sm'
			bgColor='white'
			p={{base: 4, md: 8}}
			{...props}
		>
			{children}
		</Box>
	</Center>
);

export default MobilePageContainer;
