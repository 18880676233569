import React from 'react';
import {Alert, AlertIcon, Box, VStack} from '@chakra-ui/react';
import {useQuery} from '@tanstack/react-query';
import {Puddleglum} from '../../../puddleglum';

const RecentPayments = () => {
	const {data: recentPayments} = useQuery(['recent-payments'], async () => {
		const {data} = await Puddleglum.Controllers.Employee.PaymentController.getRecentPayments();
		return data;
	});

	if (!recentPayments || recentPayments.length === 0) return null;

	return (
		<Box w='full'>
			{recentPayments.length > 0 && (
				<VStack w='full' spacing={4}>
					{recentPayments.map((billingStatement) => (
						<Box w='full' key={billingStatement.id}>
							<Alert status='success' borderRadius='md'>
								<AlertIcon />
								Thank you! Your payment of ${billingStatement.employee_price} has
								been received for Billing Statement #{billingStatement.id}.
							</Alert>
						</Box>
					))}
				</VStack>
			)}
		</Box>
	);
};
export default RecentPayments;
